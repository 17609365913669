.testimonials{
    display: flex;
    gap: 1rem;
    padding: 2rem;
}
.testimonials-left{
    display: flex;
    flex: 1 1;
    flex-direction: column;
    gap: 2rem;
    color: white;
    text-transform: uppercase;

}
.testimonials-left :nth-child(1)
{
    color: var(--orange);
    font-weight: bold  ;
}
.testimonials-left>:nth-child(2),.testimonials-left>:nth-child(3)
{
    font-size: 3rem;
    font-weight:bold;
}
.testimonials-left>:nth-child(4)
{
    text-align: justify;
    text-transform: none;
    font-weight: normal;
    letter-spacing: 0.2rem;
    line-height: 2;
}
.testimonials-right{
     position: relative;
     flex: 1;
}
.testimonials-right>img{
    position: absolute;
    width: 17rem;
    height: 20rem;
    object-fit: cover;
    right: 8rem;
    top: 2rem;
}
.testimonials-right>:nth-child(1)
{
   position: absolute;
   width: 17rem;
   height:20rem;
   right: 9rem;
   top: 0.9 rem;
   border:2px solid orange;
   background-color: transparent;

}
.testimonials-right>:nth-child(2)
{
    position: absolute;
    width: 17rem;
    height: 19rem;
    top: 4rem;
    right: 7rem;
    background: var(--planCard);
}
.arrows{
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 3rem;
    bottom: 1rem; 
}
.arrows>img{
    width: 1.5rem;
    cursor: pointer ;
}
@media screen and (max-width:768px){
    .testimonials{
        flex-direction: column;
    }
    .testimonials-left>:nth-child(2),
    .testimonials-left>:nth-child(3){
        font-size: xx-large;
    }
    .testimonials-right{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;
    }
    .testimonials-right>div{
        position: relative;
        display:none;
    }
    .testimonials-right>img{
        top: 0;
        right: 0;
        position: relative;
        align-self: center;
    }
    .testimonials-right>:last-child{
       display: block;
       bottom: 0;
       left: 0;
    }
}