.Reasons{
    padding: 0.2rem;
    display: flex;
    gap: 2rem;
    padding: 2rem;
}
.left-reasons{
    display: grid; 
    flex: 1 1;
    grid-template-columns: repeat(3,1fr); 
    gap: 1rem ;
    grid-auto-rows: 1fr;
}
.left-reasons img{
   object-fit: cover;  
}
.left-reasons :nth-child(1)
{
    width: 15rem;
     grid-row: 1/3; 
     height: 28rem;
}
.left-reasons :nth-child(2)
{ 
    width: auto;
    grid-column: 2/4;
    height: 16rem;
   
}
.left-reasons :nth-child(3)
{
   width: 14rem;
   grid-column:2/3  ;
   grid-row: 2;
}
.left-reasons :nth-child(4)
{
   width: 10rem;
   height: 11.2rem;
   grid-row: 2;
    grid-column: 3/4;
}
.right-reasons{
flex:1 1;
text-transform: uppercase;
display:flex;
flex-direction: column;
gap: 1rem;
}
.right-reasons>span{
    font-weight: bold;
    color:var(--orange);
}
.right-reasons div{
    color: white;
    font-size: 3rem;
    font-weight: bold ;
}
.details-right{
display: flex;
flex-direction: column;
gap: 1rem;

}
.details-right>div{
    display: flex;
    font-size: 1rem;
    gap: 1rem;
}
.details-right>div>img{
    width: 2rem;
    height: 2rem;
}
.partners{
    display: flex ;
    gap: 1rem;
}
.partners>img{
    width: 2.5rem;
}
@media screen and (max-width:768px){
    .Reasons{
        flex-direction: column;
    }
    .left-reasons{
        grid-auto-rows: auto;
        overflow: hidden;
    }

.left-reasons>:nth-child(1){
    width: 7rem;
    height: 17rem;
}
.left-reasons>:nth-child(2){
    width: 15rem;
    height: 10rem;
}
.left-reasons>:nth-child(3)
{
    width: 7rem;
}
.left-reasons>:nth-child(4)
{
    width: 7rem;
    height: 6rem;
}
.right-reasons>:nth-child(2)
{
font-size:xx-large;
}
}